/* eslint-disable id-blacklist */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dateFormat, dateTimeFormat, ORDER_STATUSES } from 'src/app/presentation/shared/constants';
import { IconsService } from 'src/app/presentation/shared/services/icons.service';
// Ignoring the linting check because this comes from a Kotlin Library
// @ts-ignore
import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
// @ts-ignore
import { getRejectionReasonByReasonNumberUseCase } from '@taager-experience-shared/rejection-reasons';
import { getSuspensionReasonByReasonNumberUseCase } from '@taager-experience-shared/suspension-reasons';
import { finalize, Subscription } from 'rxjs';
import { OrderActivityWithStatusModel } from 'src/app/core/domain/order/order-activity-with-status.model';
import { GetOrderActivityWithStatusUseCase } from 'src/app/core/usecases/order/order-activity-with-status.usecase';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { OrderInfo, OrderStatus } from './track-orders.interfaces';

@Component({
  selector: 'app-track-orders-dialog',
  templateUrl: './track-orders-dialog.component.html',
  styleUrls: ['./track-orders-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    NgFor,
    MatIconModule,
    NgClass,
    DatePipe,
    TranslateModule,
    LoaderComponent,
  ],
})
export class TrackOrdersDialogComponent implements OnInit {
  order: OrderInfo;

  orderProgress = 0;

  orderStatuses: OrderStatus[] = [];

  dateFormat = dateFormat;

  dateTimeFormat = dateTimeFormat;

  public isEnglishLanguage = false;

  public isLoading = false;

  private _languageChangeSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<TrackOrdersDialogComponent>,
    private _getOrderActivityWithStatusUseCase: GetOrderActivityWithStatusUseCase,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private iconsService: IconsService,
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
  ) {
    this.order = this.createOrderObject(this.data.order);
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
      },
    });
  }

  ngOnInit(): void {
    this.registerIcons();
    this.getOrderActivity();
  }

  registerIcons(): void {
    const icons = [
      'inbox',
      'clipboard',
      'checked',
      'checkmark',
      'canceled',
      'clock',
      'clock-half-dotted',
      'delivery-truck',
      'fast-delivery',
      'shipped',
      'refund-request',
      'refund-inprogress',
      'replacement-request',
      'replacement-inprogress',
      'completion-request',
      'completion-inprogress',
    ].map((icon) => {
      return this.iconsService.addIconToRegistry(
        icon,
        `../assets/img/track-order-icons/${icon}.svg`,
      );
    });
  }

  getOrderActivity(): void {
    this.isLoading = true;
    const filter: OrderActivityWithStatusModel = {
      filterObj: { orderID: this.order.id },
    };
    this._getOrderActivityWithStatusUseCase
      .execute(filter)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.orderProgress = res.data.length;
          // the API embeds the status object inside a doc object incase the user wasn't an admin
          let tempStatuses = res.data.map((item) => item.doc);
          if (
            this.orderProgress < 5 &&
            tempStatuses.filter((r) => this.checkCanceledOrSuspended(r.orderStatus)).length === 0
          ) {
            tempStatuses = this.appedDefaultStatuses(tempStatuses);
          }
          this.orderStatuses = this.createOrderStatusesArray(tempStatuses);
        },
      });
  }

  createOrderObject(order: any): OrderInfo {
    return {
      id: order.orderID || order.orderId,
      createdAt: order.createdAt,
      confirmationDate: order.confirmationDate,
      deliveryDate: order.deliveryDate,
    };
  }

  appedDefaultStatuses(tempStatuses: any): any {
    for (let i = this.orderProgress; i < 5; i++) {
      tempStatuses.push({
        orderStatus: ORDER_STATUSES.DIRECT_FLOW_STATUSES_ARRAY[i],
        updatedAt: '',
      });
    }
    return tempStatuses;
  }

  createOrderStatusesArray(orderStatuses: any): OrderStatus[] {
    const orderStatusesArray: OrderStatus[] = [];
    orderStatuses.forEach((status: any) => {
      orderStatusesArray.push({
        icon: this.mapStatustoIcon(status.orderStatus),
        statusInEnglish: status.orderStatus,
        statusTranslationKey: this.mapStatusEnglishToTranslationKey(status.orderStatus),
        canceledOrSuspended: this.checkCanceledOrSuspended(status.orderStatus),
        updatedAt: status.updatedAt,
        suspensionReason: this.getSuspensionReasonText(status.suspendedReason),
        rejectionReason: this.getRejectionReasonText(status.customerRejectedReason),
        notes: status.notes,
        failedAttemptNote: status.failedAttemptNote,
        deliverySuspendedReason: this.matchDeliverySuspendedReason(status.deliverySuspendedReason),
      });
    });
    return orderStatusesArray;
  }

  getSuspensionReasonText(suspensionReason: number): string {
    const reason = this.isEnglishLanguage
      ? getSuspensionReasonByReasonNumberUseCase(suspensionReason).englishText
      : getSuspensionReasonByReasonNumberUseCase(suspensionReason).arabicText;
    return reason;
  }

  getRejectionReasonText(rejectionReason: string): string {
    const reason = this.isEnglishLanguage
      ? getRejectionReasonByReasonNumberUseCase(rejectionReason).englishText
      : getRejectionReasonByReasonNumberUseCase(rejectionReason).arabicText;
    return reason;
  }

  matchDeliverySuspendedReason(reason: string): string {
    return ORDER_STATUSES.DELIVERY_SUSPENSION_REASONS_TRANSLATION[reason] || 'لا يوجد حالياً';
  }

  mapStatustoIcon(recievedStatus: string): string {
    const statusObject = ORDER_STATUSES.ALL_STATUSES.filter(
      (status) => status.statusInEnglish === recievedStatus,
    );
    return statusObject.length === 0 ? '' : statusObject[0].statusIcon;
  }

  mapStatusEnglishToTranslationKey(recievedStatus: string): string {
    const statusObject = ORDER_STATUSES.ALL_STATUSES.filter(
      (status) => status.statusInEnglish === recievedStatus,
    );
    return statusObject.length === 0 ? recievedStatus : statusObject[0].statusTranslationKey;
  }

  checkCanceledOrSuspended(status: string): boolean {
    return ORDER_STATUSES.SUSPENDED_CANCELED_STATUSES_ARRAY.includes(status);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
