import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-refunds-policy-dialog',
  templateUrl: './refunds-policy-dialog.component.html',
  styleUrls: ['./refunds-policy-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, TranslateModule],
})
export class RefundsPolicyDialogComponent {}
